import { getMeter } from "@/api/meter.api";
import {getAllMeters} from "@/api/meter.api";
import {createMeter, getMeterBySerialNumber, updateMeter} from "../../api/meter.api";

const state = {
    currentMeter: null,
    meterList: []
}

const getters = {
    currentMeter: (state) => state.currentMeter,
    meterList: (state) => state.meterList,
}

const actions = {
    async fetchMeter({commit}, id){
        const response = await getMeter(id);
        commit("setCurrentMeter", response.data.meter);
    },
    async fetchMeterBySerial({commit}, serial){
        const response = await getMeterBySerialNumber(serial);
        commit("setCurrentMeter", response.data.meter);
    },
    fetchAllMeters: async ({commit}) => {
        const response = await getAllMeters();
        commit("setMeterList", response.data.meters);
    },
    createMeter: async ({commit, getters}, data) => {
        if (data["apartment"]==null){
            data["apartment"] = getters.currentApartment.id;
        }
        const response = await createMeter(data);
        commit("addMeter", response.data.meter);
        return response.data.meter;
    },
    updateMeter: async ({state, commit}, data) => {
        const response = await updateMeter(state.currentMeter.id, data);
        commit("setCurrentMeter", response.data.meter);
    }
}

const mutations = {
    setCurrentMeter: (state, meter) => state.currentMeter = meter,
    setMeterList: (state, list) => state.meterList = list,
    addMeter: (state, meter) => {
        if (state.meterList) {
            state.meterList.push(meter)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
