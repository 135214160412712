import { createApp } from "vue";
import ElementPlus from 'element-plus';
import './assets/styles/theme.scss';
import App from './App.vue';
import store from './store';
import router from "./router/router";
import i18n from "./i18n";
import VueCookies from "vue3-cookies";

const app = createApp(App);
app.config.globalProperties.$goTo = (name) => {
    router.push({name})
}
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(i18n);
app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});
app.mount('#app');

const globalProperties = app.config.globalProperties;

export default globalProperties;
