import httpClient from "@/api/httpClient";

const END_POINT = "/reading"

const getReading = (id) => httpClient.get(END_POINT + "/" + id);
const getAllReadings = () => httpClient.get(END_POINT + "/");
const createReading = (data) => httpClient.post(END_POINT + "/", data)

export {
    getReading,
    getAllReadings,
    createReading,
}
