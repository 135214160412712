import httpClient from "@/api/httpClient";

const END_POINT = "/meter-type"

const getMeterType = (id) => httpClient.get(END_POINT + "/" + id);
const getAllMeterTypes = () => httpClient.get(END_POINT + "/");
const createMeterType = (data) => httpClient.post(END_POINT + "/", data)

export {
    getMeterType,
    getAllMeterTypes,
    createMeterType,
}
