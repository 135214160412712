import httpClient from "@/api/httpClient";

const END_POINT = "/apartment"

const getApartment = (id) => httpClient.get(END_POINT + "/" + id);
const getAllApartments = () => httpClient.get(END_POINT + "/");
const createApartment = (data) => httpClient.post(END_POINT + "/", data)
const getApartmentReadings = (id, year) => httpClient.get(END_POINT + "/" + id + "/readings", { params: { year: year } });
const updateApartment = (id, data) => httpClient.patch(END_POINT + "/" + id, data);



export {
    getApartment,
    getAllApartments,
    createApartment,
    getApartmentReadings,
    updateApartment
}
