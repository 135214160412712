<template>
    <el-drawer
        :with-header="false"
        v-model="show"
        v-if="!cookiePage"
        direction="btt"
        :before-close="handleClose"
        :close-on-click-modal="false"
    >
      <CookieManager @accept="handleAccept" @deny="handleDeny"></CookieManager>
    </el-drawer>
</template>

<script>
import CookieManager from "./CookieManager";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "CookieDrawer",
  components: {CookieManager},
  data(){
    return{
      show: true,
      cookiePage: false
    }
  },
  computed: {
    ...mapGetters(["getRefuseCookies"])
  },
  methods: {
    ...mapActions(["refuseCookies"]),
    handleClose(){
      console.log("handleClose")
      // if (this.$cookies.get('acceptCookie')){
      //   this.show = false;
      // } else {
      //   this.denyCookies();
      // }
    },
    handleAccept(){
      console.log("accettato")
      this.$cookies.set('acceptCookie',true);
      this.close();
    },
    handleDeny(){
      console.log("rifiuta cookies")
      //removes all cookies
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
      this.$cookies.set('acceptCookie',false);
      this.close()
    },
    close(){
      this.show = false;
    },

  },
  created() {
    if (this.$cookies.get('acceptCookie')!==null){
      // console.log(this.$cookies.get('acceptCookie'))
      this.close();
    }
  },
  watch: {
    async $route() {
      this.cookiePage = this.$route.path === "/cookie-policy";
    }
  },
}
</script>

<style scoped>

</style>