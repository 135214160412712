import httpClient from "@/api/httpClient";

const END_POINT = "/condominium"

const getCondominium = (id) => httpClient.get(END_POINT + "/" + id);
const getAllCondominia = (query) => httpClient.get(END_POINT + "/",{params: query});
const createCondominium = (data) => httpClient.post(END_POINT + "/", data);
const updateCondominium = (id, data) => httpClient.patch(END_POINT + "/" + id, data);
const setActiveCondominium = (id, status) => httpClient.patch(END_POINT + "/" + id + "/active", {active: status})

export {
    getCondominium,
    getAllCondominia,
    createCondominium,
    updateCondominium,
    setActiveCondominium
}
