import httpClient from "@/api/httpClient";

const END_POINT = "/user"

const getUser = (id) => httpClient.get(END_POINT + "/" + id);
const getAllUsers = (query) => httpClient.get(END_POINT + "/", {params: query});
const registerUser = (data) => httpClient.post(END_POINT + "/register", data);
const activateUser = (data) => httpClient.post(END_POINT + "/activate", data);
const updateUser = (id, data) => httpClient.patch(END_POINT + "/" + id, data);
const updateUserRoles = (id, roles) => httpClient.patch(`${END_POINT}/${id}/roles`, roles);
const updateUserActive = (id, data) => httpClient.patch(`${END_POINT}/${id}/active`, data);
const getAllBasicUsers = () => httpClient.get(END_POINT + "/", { params: { type: 'user' } });
const getAllHomelessUsers = () => httpClient.get(END_POINT + "/", { params: { type: 'user', noHome: true } });
const updatePassword = (id, data) => httpClient.patch(`${END_POINT}/${id}/password`, data);
const resetPassword = (id) => httpClient.post(`${END_POINT}/${id}/password/reset`);
const updateImgData = (id, data) => httpClient.patch(`${END_POINT}/${id}/img`, data);

export {
    getUser,
    getAllUsers,
    registerUser,
    activateUser,
    updateUser,
    updateUserRoles,
    updateUserActive,
    getAllBasicUsers,
    getAllHomelessUsers,
    updatePassword,
    resetPassword,
    updateImgData
}
