import {getAllApartments, getApartment} from "@/api/apartment.api";
import {createApartment, getApartmentReadings, updateApartment} from "../../api/apartment.api";
import moment from "moment";

const MONTH_FORMAT = "YYYY-MM";

const state = {
    currentApartment: null,
    apartmentList: [],
    currentApartmentReadingTotals: {},
    currentApartmentMonthlyData: {},
}

const getters = {
    currentApartment: (state) => state.currentApartment,
    apartmentList: (state) => state.apartmentList,
    currentApartmentReadingTotals: (state) => state.currentApartmentReadingTotals,
    currentApartmentMonthlyData: (state) => state.currentApartmentMonthlyData,
}

const actions = {
    async fetchApartment({commit}, id) {
        const response = await getApartment(id);
        commit("setCurrentApartment", response.data.apartment);
        commit("resetCurrentApartmentReadingList");
    },
    fetchAllApartments: async ({commit}) => {
        const response = await getAllApartments();
        commit("setApartmentList", response.data.apartments);
    },
    updateApartment: async ({commit, state}, data) => {
        const response = await updateApartment(state.currentApartment.id, data);
        commit("setCurrentApartment", response.data.apartment);
    },
    createApartment: async ({commit, getters}, data) => {
        if (data["condominium"] == null) {
            data["condominium"] = getters.currentCondominium.id;
        }
        const response = await createApartment(data);
        commit("addApartment", response.data.apartment);
        return response.data.apartment;
    },
    fetchReadings: async ({state, commit}, data) => {
        let id, year, end;



        if (data["id"] == null && state.currentApartment != null) {
            id = state.currentApartment.id;
        } else {
            id = data["id"];
        }
        if (data["year"] != null) {
            year = data["year"];
        }
        const type = data["type"] || 4;


        const response = await getApartmentReadings(id, year);
        let meters = response.data.meterReadings.filter(meter => meter.meterType.id === type);
        const totals = {};
        // SETUP TOTALI
        totals.monthlyReadings = new Map();

        const startDate = moment(year + "-" + state.currentApartment.condominium.readingsStartDate)

        if (state.currentApartment.condominium.readingsEndDate){
            let endDate = moment(year + "-" + state.currentApartment.condominium.readingsEndDate)
            if (startDate.isAfter(endDate)){
                endDate = moment((year+1) + "-" + state.currentApartment.condominium.readingsEndDate)
            }
            end = Math.min(endDate.diff(startDate, "months")+1, 12)
        } else {
            end = 12;
        }

        let date = startDate.startOf("month");

        for (let i = 0; i < end; i++) {
            if (date.isAfter(moment())){
                break;
            }
            totals.monthlyReadings.set(date.format(MONTH_FORMAT), {date: date.clone(), reading: 0, consumption: 0});
            date.add(1, "month");
        }

        // Converto la data in moment e creo la lista per mese
        meters.forEach(meter => {
            meter.readings.forEach(r => {
                r.dateTime = moment(r.dateTime);
            })

            // Creo la lista delle letture mensili vuota
            const mr = new Map();
            date = moment(year + "-" + state.currentApartment.condominium.readingsStartDate).startOf("month");

            for (let i = 0; i < end; i++) {
                if (date.isAfter(moment())){
                    break;
                }
                mr.set(date.format(MONTH_FORMAT), {date: date.clone(), reading: 0, consumption: 0, auto: true});
                date.add(1, "month");
            }
            // Popolo con l'ultima lettura di ogni mese

            meter.readings.forEach(r => {
                if(mr.has(r.dateTime.format(MONTH_FORMAT))){
                    mr.get(r.dateTime.format(MONTH_FORMAT)).reading = r.value;
                    mr.get(r.dateTime.format(MONTH_FORMAT)).auto = false;
                }
            })
            // Popolo mesi vuoti vuote e i consumi
            let last_reading = 0;
            mr.forEach(r => {
                if (r.reading === 0 && r.auto) {
                    r.reading = last_reading;
                }
                r.consumption = r.reading - last_reading;
                last_reading = r.reading;

                totals.monthlyReadings.get(r.date.format(MONTH_FORMAT)).reading +=  r.reading;
                totals.monthlyReadings.get(r.date.format(MONTH_FORMAT)).consumption +=  r.consumption;
            });

            meter.monthlyReadings = mr;
        })

        console.log(meters)

        commit("setCurrentApartmentMonthlyData", {year: year, readings: meters});
        commit("setCurrentApartmentReadingTotals", {year: year, readings: totals});

    },
    clearReadings: ({commit})=> {
        commit("clearReadings");
    }
}

const mutations = {
    setCurrentApartment: (state, apartment) => state.currentApartment = apartment,
    addMeter: (state, meter) => {
        if (state.currentApartment) {
            state.currentApartment.meters.push(meter);
        }
    },
    setApartmentList: (state, list) => state.apartmentList = list,
    resetCurrentApartmentReadingList: (state) => {
        state.currentApartmentReadingList = {};
        state.currentApartmentReadingTotals = {};
    },
    setCurrentApartmentReadingTotals: (state, {year, readings}) => state.currentApartmentReadingTotals[year] = readings,
    setCurrentApartmentMonthlyData: (state, {year, readings}) => state.currentApartmentMonthlyData[year] = readings,
    clearReadings: (state) => {
        state.currentApartmentMonthlyData = {};
        state.currentApartmentReadingTotals = {};
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
