import {login} from "../../api/app.api";

import jwt_decode from "jwt-decode";

const permissions = {
    "ROLE_USER": 0,
    "ROLE_CONDO": 1,
    "ROLE_MANAGER": 2,
    "ROLE_ADMIN": 3
}


const state = {
    sidebarIsCollapse: false,
    loading: false,
    forceLoading: false,
    user: null,
    firstAccess: false,
    tmpUserData: {},
}

const getters = {
    sidebarIsCollapse: (state) => state.sidebarIsCollapse,
    isLoading: state => state.loading,
    isLogged: state => state.user!==null,
    isFirstAccess: state => state.firstAccess,
    userFullname: state => state.user.surname + " " + state.user.name,
    userRoles: state => state.user ? state.user.roles : [],
    appUser: state => state.user,
    isGranted: state => role => {
        if(!state.user){
            return false;
        }
        const target = permissions[role];
        for(let r of state.user.roles){
            if(permissions[r] >= target){
                return true;
            }
        }
    },
    getUserActivationData: state=> state.tmpUserData,
}

const actions = {
    extendSidebar: ({commit}) => commit("setSidebarIsCollapse", false),
    collapseSidebar: ({commit}) => commit("setSidebarIsCollapse", true),
    toggleSidebar: ({commit, state}) => commit("setSidebarIsCollapse", !state.sidebarIsCollapse),
    startLoading: ({commit}) => commit("setLoading", true),
    stopLoading: ({commit, state}) => commit("setLoading", state.forceLoading),
    stopForceLoading: ({commit}) => {
        commit("setLoading", false);
        commit("setForceLoading", false);
    },
    startForceLoading: ({commit}) => {
        commit("setLoading", true);
        commit("setForceLoading", true);
    },
    login: async ({commit}, data) => {
        let response = await login(data);
        const user = jwt_decode(response.data.token);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", response.data.token);
        commit("setUser", user)
    },
    logout: ({commit}) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        commit("setUser", null);
    },
    loadPageUser: ({commit}) => {
        let user = localStorage.getItem("user");
        if(user && user!==""){
            commit("setUser", JSON.parse(user))
        }
    },
    setUserActivationData: ({commit}, data) => {
        commit("setTmpUserData", data);
    },

}

const mutations = {
    setSidebarIsCollapse: (state, value) => state.sidebarIsCollapse = value,
    setLoading: (state, value) => state.loading = value,
    setUser: (state, user) => state.user = user,
    setForceLoading: (state, value) => state.forceLoading = value,
    setFirstAccess: (state, value) => state.firstAccess = value,
    setTmpUserData: (state, userData) => state.tmpUserData = userData,
}

export default {
    state,
    getters,
    actions,
    mutations
}
