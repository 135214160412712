import { getCondominium } from "@/api/condominium.api";
import {getAllCondominia} from "@/api/condominium.api";
import {createCondominium} from "@/api/condominium.api";
import {updateCondominium, setActiveCondominium} from "@/api/condominium.api";

const state = {
    currentCondominium: null,
    condominiumList: [],
    totalCondominia: 0
}

const getters = {
    currentCondominium: (state) => state.currentCondominium,
    condominiumList: (state) => state.condominiumList,
    totalCondominia: (state) => state.totalCondominia,
}

const actions = {
    async fetchCondominium({commit}, id){
        const response = await getCondominium(id);
        commit("setCurrentCondominium", response.data.condominium);
    },
    fetchAllCondominia: async ({commit}, query) => {
        const response = await getAllCondominia(query);
        commit("setCondominiumList", response.data.condominia);
        commit("setTotalCondominia", response.data.total);
    },
    createCondominium: async ({commit}, data) => {
        const response = await createCondominium(data);
        commit("addCondominium", response.data.condominium);
        return response.data.condominium
    },
    updateCondominium: async ({state}, data) => {
        await updateCondominium(state.currentCondominium.id, data);
        //commit("setCurrentCondominium", response.data.condominium);
    },
    disableCondominium: async ({commit, state}) => {
        const response = await setActiveCondominium(state.currentCondominium.id, false);
        commit("setCurrentCondominium", response.data.condominium);
    },
    activateCondominium: async ({commit, state}) => {
        const response = await setActiveCondominium(state.currentCondominium.id, true);
        commit("setCurrentCondominium", response.data.condominium);
    },
}

const mutations = {
    setCurrentCondominium: (state, condominium) => state.currentCondominium = condominium,
    setCondominiumList: (state, list) => state.condominiumList = list,
    setTotalCondominia: (state, total) => state.totalCondominia = total,
    addApartment: (state, apartment) => {
        if (state.currentCondominium) {
            state.currentCondominium.apartments.push(apartment)
        }
    },
    addCondominium: (state, condominium) => {
        if (state.condominiumList) {
            state.condominiumList.push(condominium)
        }
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}
