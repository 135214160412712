import httpClient from "@/api/httpClient";

const END_POINT = "/meter"

const getMeter = (id) => httpClient.get(END_POINT + "/" + id);
const getMeterBySerialNumber = (serial) => httpClient.get(END_POINT + "/serial/" + serial);
const getAllMeters = () => httpClient.get(END_POINT + "/");
const createMeter = (data) => httpClient.post(END_POINT + "/", data);
const updateMeter = (id, data) => httpClient.patch(END_POINT + "/" + id, data);

export {
    getMeter,
    getMeterBySerialNumber,
    getAllMeters,
    createMeter,
    updateMeter
}
