import { createStore } from "vuex"


import app from "@/store/modules/app.module"
import user from "@/store/modules/user.module"
import meter from "@/store/modules/meter.module"
import condominium from  "@/store/modules/condominium.module"
import apartment from "@/store/modules/apartment.module"
import meterType from "@/store/modules/meterType.module"
import reading from "@/store/modules/reading.module"

const store = createStore({
    modules: {
        app,
        user,
        meter,
        condominium,
        apartment,
        meterType,
        reading
    }
})
export default store