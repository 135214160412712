import { getReading } from "@/api/reading.api";
import {getAllReadings} from "@/api/reading.api";
import {createReading} from "../../api/reading.api";

const state = {
    currentReading: null,
    readingList: []
}

const getters = {
    currentReading: (state) => state.currentReading,
    readingList: (state) => state.readingList,
}

const actions = {
    async fetchReading({commit}, id){
        const response = await getReading(id);
        commit("setCurrentReading", response.data.reading);
    },
    fetchAllReadings: async ({commit}) => {
        const response = await getAllReadings();
        commit("setReadingList", response.data.readings);
    },
    createReading: async ({commit, }, data) => {
        // if (data["meter"]==null && data["serialNumber"]==null){
        //     data["meter"] = getters.currentMeter.id;
        // }
        const response = await createReading(data);
        commit("addReading", response.data.reading);
        return response.data.reading;
    }
}

const mutations = {
    setCurrentReading: (state, reading) => state.currentReading = reading,
    setReadingList: (state, list) => state.readingList = list,
    addReading: (state, reading) => {
        if (state.readingList) {
            state.readingList.push(reading)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
