<template>
    <h3>{{$t('cookieDrawer.title')}}</h3>
    <p> {{$t("cookieDrawer.text")}}
      <el-link class="el-link" @click="$goTo('cookie-policy')">
        Cookie Policy
      </el-link>
    </p>
    <el-button type="primary" @click="accept">{{$t("buttons.acceptCookies")}}</el-button>
    <el-button type="primary" plain @click="refuse">{{$t("buttons.denyCookies")}}</el-button>
</template>

<script>
export default {
  name: "CookieManager",
  emits: ["accept", "deny"],
  methods: {
    accept(){
      this.$emit('accept', true);
    },
    refuse(){
      this.$emit('deny', true);
    }
  }
}
</script>

<style scoped>

</style>