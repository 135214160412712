import { getMeterType } from "@/api/meterType.api";
import {getAllMeterTypes} from "@/api/meterType.api";
import {createMeterType} from "../../api/meterType.api";

const state = {
    currentMeterType: null,
    meterTypeList: []
}

const getters = {
    currentMeterType: (state) => state.currentMeterType,
    meterTypeList: (state) => state.meterTypeList,
}

const actions = {
    async fetchMeterType({commit}, id){
        const response = await getMeterType(id);
        commit("setCurrentMeterType", response.data.meterType);
    },
    fetchAllMeterTypes: async ({commit}) => {
        const response = await getAllMeterTypes();
        commit("setMeterTypeList", response.data.meterTypes);
    },
    createMeterType: async ({commit}, data) => {
        const response = await createMeterType(data);
        commit("addMeterType", response.data.meterType);
    }
}

const mutations = {
    setCurrentMeterType: (state, meterType) => state.currentMeterType = meterType,
    setMeterTypeList: (state, list) => state.meterTypeList = list,
    addMeterType: (state, meterType) => {
        if (state.meterTypeList) {
            state.meterTypeList.push(meterType);
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
